import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/organisms/header"
import Footer from "../components/organisms/footer"
import Hero from "../components/organisms/hero"
import Clients from "../components/molecules/clients"
import OurWork from "../components/molecules/our-work"
import Services from "../components/organisms/services"
import Sentences from "../components/organisms/sentences"
import Contacts from "../components/organisms/contacts"
import { clients } from "../utils/clients"
import { sentences } from "../utils/sentences"

const services = [
  {
    icon: "translate",
    title: "Translation",
    text:
      "We translate your texts in their meaning and intent while keeping true to the style and rhythm with which you communicate.",
  },
  {
    icon: "revision",
    title: "Revision",
    text:
      "Text revision may just be simple spell checks to make what you write right, or we can take it up a level and suggest changes that will make your communication clearer and more objective.",
  },
  {
    icon: "creation",
    title: "Content Creation",
    text:
      "You have an idea of what you want to say but just can’t find the words? We have plenty to share. We’ll help create what you thought so that the result will be even better than you imagined.",
  },
]

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Marketing English and Portuguese" />
      <Header
        i18n={<a href="/">PT</a>}
        linkedin="https://www.linkedin.com/company/inglesparamarketing"
        facebook="https://facebook.com/marketINGinglesparamarketing"
      />
      <Hero
        title="Marketing English and Portuguese"
        subtitle="Translations, editing and content creation PT/ENG - ENG/PT"
        buttonUrl="#our-work"
        button="Know More"
      />
      <Clients clients={clients} />
      <OurWork
        id="our-work"
        title="What we do?"
        text="Marketing materials for businesses and professionals that communicate in English or English and Portuguese. Sites, newsletters, corporate communication, e-mails, social media, articles, press releases, advertisements and much more."
      />
      <Services services={services} />
      <Sentences slides={sentences} />
      <Contacts
        title="Contacts"
        description="We’re bilingual Portuguese and English. We understand both languages in their secrets, little tricks and beauty. If you’re looking at giving your communication a more native tone, get in touch."
        email="ola@inglesparamarketing.com"
      />
      <Footer
        linkedin="https://www.linkedin.com/company/inglesparamarketing"
        facebook="https://facebook.com/marketINGinglesparamarketing"
        copyright={`Copyright © ${new Date().getFullYear()} All rights reserved.`}
      />
    </Layout>
  )
}

export default IndexPage
